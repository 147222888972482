<template>
    <div class="AdjustmentHeight full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="exportPath" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p>{{ $t('report.selectPathExport') }}</p>
                        <v-list>
                            <v-list-item-group
                                v-model="exportPath"
                                color="disiIrisblue"
                                class="list mt-4 mb-8"
                                id="pathdialog-list"
                                mandatory
                            >
                                <v-list-item
                                    v-for="(item) in this.pathlist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">{{ $t('footerButton.cancel') }}</v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="exportPath == ''" @click="$refs.pathdialog.save(exportPath); doExport()">{{ $t('footerButton.save') }}</v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>

            <v-dialog ref="discdialog" v-model="discmodal" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p>{{ $t('adjCal.newDiscID') }}</p>
                        <text-input class="mt-2 mb-8" inputName="adjustmentValue-discId" @onInputFocus="onInputFocus" :rules="[v => !!v || $t('validation.required'), rules.alphabeticName]"/>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="discmodal = false">{{ $t('footerButton.cancel') }}</v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="adjustmentValue.discId.length < 3" @click="newDisc = true; discmodal = false; startAdjust()">{{ $t('footerButton.start') }}</v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>

            <navigation-left-small noBackButton disabled/>
            <div class="content-area pa-5 flex-grow-1 bg-white">
<!--                Start-->
                <p class="pa-3" v-if="screen === 'start'"> {{ $t('adjCal.securityInfo') }}</p>
                <p class="pa-3" v-if="screen === 'start'"> {{ $t('adjCal.adjustHeightStartInfo') }}</p>
<!--                Progress-->
                <div v-if="screen === 'progress'" class="full-height container-progress">
                    <div class="container-loader pa-3 pb-6 full-height d-flex flex-column justify-center align-center">
                        <p class="pa-3 activity" > {{ progressBar.activity }}</p>

                        <v-progress-linear
                            color="disiIrisblue"
                            :value=progressBar.progress
                            background-color="disiBluegreyLight"
                            height="32"
                            buffer-value="0"
                            stream
                        >
                            <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                    </div>
                </div>
<!--                Temperature-->
                <p class="pa-3" v-if="screen === 'temperature'"> {{ $t('adjCal.adjustHeightTempTitle') }}</p>
                <v-form ref="form">
                    <v-row class="d-flex" v-if="screen === 'temperature'">
                        <v-col cols="6" class="pa-0">
                            <v-row class="d-flex align-center">
                                <v-col class="col-4">
                                    <p>{{ $t('adjCal.temperature') }}</p>
                                </v-col>
                                <v-col class="col-4">
                                    <text-input inputName="adjustmentValue-temperature" @onInputFocus="onInputFocus" onlyNumbers suffix="°C" :rules="[v => !!v || $t('validation.required'), rules.adjustmentValue, rules.maxDecimalPlaces1]"/>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
<!--                Finish-->
                <p class="pa-3" v-if="screen === 'finish'"> {{ $t('adjCal.adjustHeightFinishTitle') }}</p>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
<!--            4-->
            <footer-button v-if="screen === 'start'" buttonFunction="newDisc" @footerButtonClick="discmodal = true"></footer-button>
            <footer-button v-if="screen != 'finish' && screen != 'start'" ></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="export" :successCheck="showExportSuccess" :progress="showExportProgress" @footerButtonClick="pathmodal = true" :class="{'disabled': !checkUserRights('adjCalExport')}"></footer-button>
<!--            5-->
            <footer-button v-if="screen != 'finish' && cancel" buttonFunction="cancel" @footerButtonClick="goToAdjustment" ></footer-button>
            <footer-button v-if="screen === 'finish'" buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress" @footerButtonClick="printReport" :class="{'disabled': !checkUserRights('adjCalPrint')}"></footer-button>
<!--            6-->
            <footer-button v-if="screen === 'start'" buttonFunction="start" @footerButtonClick="startAdjust"></footer-button>
            <footer-button v-if="screen === 'progress'"></footer-button>
            <footer-button buttonFunction="ok" @footerButtonClick="finishAdjust" v-if="screen === 'temperature'" :class="{'disabled': adjustmentValue.temperature === ''}"></footer-button>
            <footer-button buttonFunction="ok" @footerButtonClick="goToAdjustment" v-if="screen === 'finish'"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import TextInput from "@/components/TextInput.vue";
import NavigationLeftSmall from "@/components/NavigationLeftSmall.vue";
import mixins from "@/mixins/mixins";

export default {
    name: 'AdjustmentHeight',
    components: {
        NavigationLeftSmall,
        TextInput,
        FooterButton,
    },
    props: {
    },
    data () {
        return{
            screen: 'start',
            cancel: true,
            calibrationId: 0,
            showPrintProgress: false,
            showPrintSuccess: false,
            showExportProgress: false,
            showExportSuccess: false,
            pathmodal: false,
            exportPath: '',
            discmodal: false,
            newDisc: false,
            rules: {
                alphabeticName: v => {
                    if (v) {
                        let alphabetic = /^[A-Za-z]+$/;
                        return alphabetic.test(v) || `${this.$t('validation.pleaseEnterValidName')}`;
                    } else return true;
                },
                adjustmentValue: v => {
                    if (v) {
                        return parseFloat(v) <= 40 || `${this.$t('validation.maximum')} 40°C`;
                    } else return true;
                },
                maxDecimalPlaces1: v => {
                    if (v){
                        let test = v.toString().split('.');
                        if(test[1]){
                            if(test[1].length > 1){
                                return `${this.$t('validation.chooseShorterDecimalPlace')}`;
                            }
                        }
                    } else return true;
                }
            }
        }
    },
    computed: {
        ...mapState([
            'adjustmentValue',
            'adjustmentProcess',
            'activeStationId',
            'progressBar',
            'pathlist',
        ]),
    },
    methods:{
        goToAdjustment(){
            this.$store.commit('resetAdjustmentHeight');
            this.$emit("resetInput");
            this.$router.push('adjustment');
        },
        startAdjust(){
            let payload = {id: this.activeStationId};
            if (this.newDisc === true){
                payload = {
                    id: this.activeStationId,
                    discId: this.adjustmentValue.discId
                }
            }
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjustHeight/start', payload])
                .then(response => {
                    this.$store.commit('resetAdjustmentHeight');
                    this.newDisc = false;
                    if (response.status === 200) {
                        this.calibrationId = response.data.calibrationId;
                        this.screen = 'progress';
                        this.getProgress();
                    }
                })
        },
        getProgress(){
            this.$store.dispatch('postAxiosSetter', ['/disi/calibration/progress', {id: this.activeStationId}, 'progressBar'])
                .then(response=>{
                    if (response.status === 200){
                        if (!response.data.cancel){
                            this.cancel = false;
                        }
                        if (response.data.progress && response.data.progress < 100 && this.$router.currentRoute.name == 'AdjustmentHeight'){
                            setTimeout(()=>{
                                this.getProgress();
                            }, 500)
                        } else{
                            this.screen = 'temperature';
                        }
                    } else {
                        this.screen = 'start';
                    }
                })
        },
        finishAdjust(){
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjustHeight/measuredTemperature', {
                    calibrationId: this.calibrationId,
                    temperature: parseFloat(this.adjustmentValue.temperature)}])
                    .then(response =>{
                        if (response.status === 200) {
                            this.screen = 'finish';
                            this.cancel = true;
                            this.$store.commit('resetAdjustmentHeight');
                            this.$emit("resetInput");
                        }
                    })
            }
        },
        doExport(){
            this.showExportProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjust/export', {'adjustmentId': [this.calibrationId], 'pathId': this.exportPath}])
                .then(response =>{
                    this.showExportProgress = false;
                    if (response.status === 200) {
                        this.showExportSuccess = true;
                        setTimeout(() => {
                            this.showExportSuccess = false;
                        }, 800)
                    }
                })
        },
        printReport(){
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjust/print', {'adjustmentId': [this.calibrationId]}])
                .then(response =>{
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(() => {
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                })
        },
        // Keyboard functions ------------------ //
        onInputFocus(input, onlyNumbers) {
            this.$emit('onInputFocus', [input.target.id, onlyNumbers])
            this.$vuetify.goTo('#'+input.target.id, {duration: 100, offset: 80, container: ".content-area"});
        },
    },
    mixins: [mixins],
    beforeRouteLeave (to, from, next) {
        this.$store.commit('resetAdjustmentHeight');
        this.$emit("resetInput");
        if(this.screen === 'start' || this.screen === 'finish'){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.cancelAdjustment'), show: true, cancelText: this.$t('general.cancel'), continueText: this.$t('general.continue') })
                .then(() => {
                    // continue
                    this.$store.dispatch('postAxiosNoSetter', ['/disi/calibration/adjust/cancel', {'adjustmentId': this.calibrationId}]);
                    this.screen = 'start';
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
.container-progress{
    position: relative;
}
.container-loader{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.activity{
    min-height: 60px;
}
.loader{
    strong{
        color: $disiGrey;
    }
}
</style>